<template>
  <v-container>
    <div class="Rectangle-32">
      <v-sheet class="pa-12" color="white lighten-3">
        <v-sheet class="mx-auto" maxHeight="512" maxWidth="815">
          <div class="cen">
            <img
              class="img"
              src="../assets/Group.png"
              alt="teller logo"
              width="40"
              height="40"
            />
            <p class="tex display-1">Transaction Succesful</p>
            <v-btn @click="goback()" dark large class="btnn">Done</v-btn>
          </div>
        </v-sheet>
      </v-sheet>
    </div>
  </v-container>
</template>
  <script>
export default {
  methods: {
    goback() {
      this.$router.replace({ name: "Dashboard" });
    },
  },
};
</script>
  <style scoped>
.Rectangle-32 {
  width: 100%;
  height: 100%;
  padding-top: 90px;
  background-color: rgba(255, 255, 255, 0.97);
}
.cen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}
.tex {
  text-align: center;
  color: #444444;
}
.img {
  width: 210.1px;
  height: 227px;
  object-fit: contain;
}
.btnn {
  width: 162px;
  height: 55px;
  border-radius: 15px;
  background-color: #4db472 !important;
}
</style>
  